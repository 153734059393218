body {
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  overflow-y: hidden;
}

*{
  box-sizing: border-box;
}

.loader{
  background: black;
  color: white;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 2;
}

.progress{
  position: absolute;
  top: 52%;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  background-color: white;
  transition: all 1s ease;
}

.nav--bar{
  position: absolute;
  display: flex;
  align-content: space-between;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.logo{
  margin-left: 40px;
}

.section{
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
}

.section--one--container{
  width: 360px;
  margin-left: 15%;
}

.first h1{
  font-size: 10rem;
  line-height: 8rem;
  letter-spacing: -0.3rem;
  width: 50%;
  margin: 0 0 30px -10px;
}

.button--hero{
  background: #F2CE6E;
  color: black;
  border: none;
  font-weight: 600;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 24px;
  margin-right: 40px;
  font-size: 0.6rem;
  cursor: pointer;
  transition: all 0.8s ease;
}

.button--customize{
  background: #F2CE6E;
  color: black;
  border: none;
  font-weight: 600;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 24px;
  margin-right: 40px;
  font-size: 0.6rem;
  cursor: pointer;
  transition: all 0.8s ease;
}
.button--hero:hover{
  background: black;
  color: white;
}

.button--navbar{
  background: black;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 30px;
  margin-right: 40px;
  font-size: 0.6rem;
}

.second{
  justify-content: flex-end;
  position: relative;
}

.section--two--container{
  width: 360px;
  margin-right: 10%;
  z-index: 1;
}

.section--third--container{
  width: 360px;
  margin-left: 10%;
  z-index: 1;
}

h2{
  font-size: 3rem;
  letter-spacing: -2px;
  line-height: 2.5rem;
}

.custom--bg{
  width: 100%;
  height: 800px;
  background: #F2CE6E;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  transform: skew(20deg) rotate(21deg);
  position: absolute;
  top: 0;
  left: 0;
}

footer{
  background: black;
  padding: 70px;
  display: flex;
  justify-content: center;
}

.button--footer{
  background: white;
  color: black;
  border: none;
  border-radius: 50px;
  padding: 10px 30px;
  font-size: 0.6rem;
  cursor: pointer;
  transition: all 0.8s ease;
}

.button--footer:hover{
  background: black;
  color: white;
}

#webgi-canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

#webgi-canvas-container{
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.button--exit{
  width: 90px;
  margin-bottom: 5%;
  z-index: 5;
  position: fixed;
  bottom: 0;
  display: none;
}

.customizer--container{
  position: fixed;
  top: 50%;
  display: none;
  transform: translateY(-50%);
}

.strap--colors{
  list-style: none;
}

.button--colors{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid white;
  padding: 0;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.8s ease;
}

.button--colors:hover{
  filter: drop-shadow(0 0 0.75rem rgba(28, 17, 52, 0.704));
  transform: scale(1.5);
}

.yellow{
  background-color: #F0D56C;
}

.red{
  background-color: rgb(231, 34, 34);
}

.black{
  background-color: rgb(74, 76, 79);
}

/* MOBILE ADJUSTMENTS */

@media (max-width: 600px) {


  html, body, .container, nav{
    overflow-x: hidden;
  }

  .first h1{
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: -0.1rem;
    width: 50%;
    margin: 0 0 15px 0px;
  }

  .nav--bar{
    margin-top: 0;
    z-index: 1;
    background: #ffffffad;
    padding: 30px 0;
    backdrop-filter: blur(5px);
  }
  
  .section--one--container {
    width: 360px;
    margin-left: 6%;
    margin-top: 40%;
  }

  .section--one--container > p {
    width: 70%;
  }

  .second{
    align-items: flex-end;
  }
  
  .section--third--container {
    width: 220px;
    margin-left: 7%;
  }
}
